import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AUTH_ATTEMPTS_KEY,
    REQUEST_ATTEMPTS_KEY,
    TOKEN_KEY,
    USER_PHONE_NUMBER_KEY,
} from 'shared/const/localStorage';
import { useAppSelector } from 'shared/lib/react/hooks';
import { saveInStorage } from 'shared/lib/saveInStorage';
import { getAuthToken } from '../selectors/getAuthToken';
import { AuthSchema, Token, User } from '../types/authSchema';

const user: User = {
    phoneNumber: localStorage.getItem(USER_PHONE_NUMBER_KEY) || '',
    requestAttempts: Number.parseInt(
        localStorage.getItem(REQUEST_ATTEMPTS_KEY) || '',
    ),
    authAttempts: Number.parseInt(
        localStorage.getItem(AUTH_ATTEMPTS_KEY) || '',
    ),
};

const initialState: AuthSchema = {
    token: localStorage.getItem(TOKEN_KEY) || null,
    user: user,
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<Token>) => {
            state.token = action.payload;
        },
        setAuthData: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        decrementRequestAttempts: (state) => {
            state.user && state.user.requestAttempts--;
        },
        decrementAuthAttempts: (state) => {
            if (state.user) {
                state.user.authAttempts--;
                saveInStorage(AUTH_ATTEMPTS_KEY, state.user.authAttempts.toString());
            }
        },
        logOut: (state) => {
            state.token = null;
        },
    },
});

export const useGetToken = () => {
    return useAppSelector(getAuthToken);
};
export const { reducer: authReducer, actions: authActions } = slice;
