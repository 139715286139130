
export function formatPhoneNumber(phone: string) {

    let numericPhone = phone.replace(/\D/g, '');
  
    if(numericPhone.startsWith('7')) {
      numericPhone = numericPhone.slice(1); 
    }
  
    if(!numericPhone.startsWith('8')) {
      numericPhone = '8' + numericPhone; 
    }
  
    return numericPhone.slice(0, 11); 
  }

