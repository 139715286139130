import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { forOwn } from 'lodash';
import { useEffect } from 'react';
import { FieldError, Path } from 'react-hook-form';

export function useHandleErrors<T extends unknown>(
    error: FetchBaseQueryError | SerializedError | undefined,
    setError: (
        name: keyof T,
        error: FieldError,
        options?: { shouldFocus: boolean },
    ) => void,
    validate?: Path<T>[],
) {
    useEffect(() => {
        if (error && 'status' in error && error.status === 400) {
            'data' in error
                ? (error.data as Record<keyof T, string[]>)
                : ({} as Record<keyof T, string[]>);

            forOwn(error.data, (v, k) => {
                if (validate?.includes(k as Path<T>)) {
                    setError(k as keyof T, { type: 'custom', message: v[0] });
                }
            });
        }
    }, [error, setError, validate]);
}
