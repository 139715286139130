import React, { ReactElement } from 'react';
import {
    createBrowserRouter,
    useLocation,
    Navigate,
    Outlet,
    useOutletContext,
} from 'react-router-dom';
import { LoginPage } from 'pages/login';
import { LogoutPage } from 'pages/logout';
import { MainPage } from 'pages/main';
import { EditOrderPage, OrdersPage } from 'pages/orders';
import { useGetToken } from 'features/auth';
import { MainLayout } from './layouts/MainLayout';

function GuestGuard() {
    const context = useOutletContext();
    const token = useGetToken();
    const location = useLocation();

    if (!token)
        return <Navigate to="/login" replace state={{ from: location }} />;

    return <Outlet context={context} />;
}

type AuthGuardProps = {
    children: ReactElement;
    toComponent?: ReactElement;
};

function AuthGuard({ children, toComponent }: AuthGuardProps) {
    const token = useGetToken();

    if (token) return toComponent ? toComponent : <Navigate to={'/'} replace />;

    return children;
}

export const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: (
                    <AuthGuard toComponent={<OrdersPage />}>
                        <MainPage />
                    </AuthGuard>
                ),
            },
            {
                path: '/orders',
                element: <GuestGuard />,
                children: [
                    {
                        index: true,
                        element: <OrdersPage />,
                    },
                    {
                        path: ':id/edit',
                        element: <EditOrderPage />,
                    },
                ],
            },
        ],
    },
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/login',
                element: (
                    <AuthGuard>
                        <LoginPage />
                    </AuthGuard>
                ),
            },
            {
                path: '/logout',
                element: <LogoutPage />,
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/" />,
    },
]);
