import React, {
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
    ReactNode,
} from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';
import { Field, FieldChildrenProps } from '../../../ui/Field';
import cls from './Input.module.css';

type InputProps<T extends FieldValues> = FieldChildrenProps<T> & {
    type?: HTMLInputTypeAttribute;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    mask?: string;
    icon?: ReactNode;
    rightText?: string | number;
    registerOptions?: RegisterOptions;
};

export const Input = <T extends FieldValues>({
    formKey,
    label,
    error,
    size,
    mask,
    fieldClassName,
    isLoading,
    inputProps,
    hint,
    icon,
    rightText,
    register,
    registerOptions,
}: InputProps<T>) => {
    const registerWithMask = useHookFormMask(register);
    return (
        <Field
            formKey={formKey}
            label={label}
            error={error}
            hint={hint}
            required={!!registerOptions?.required || inputProps?.required}
            size={size}
            fieldClassName={fieldClassName}
            isLoading={isLoading}>
            <div className="relative">
                {mask ? (
                    <input
                        style={{
                            paddingRight: rightText && rightText.toString().length * 8 + 16
                        }}
                        {...registerWithMask(
                            formKey,
                            mask,
                            registerOptions as any,
                        )}
                        {...inputProps}
                        id={formKey}
                    />
                ) : (
                    <input
                        {...register(formKey, registerOptions)}
                        {...inputProps}
                        id={formKey}
                    />
                )}
                {icon && (
                    <label htmlFor={formKey} className={cls.iconWrapper}>
                        {icon}
                    </label>
                )}
                {!!rightText && (<div className="pointer-events-none absolute right-2 top-1/2 flex -translate-y-1/2 items-center pr-3">
                    <span className="text-text-secondary sm:text-sm">{rightText}</span>
                </div>)}
                
            </div>
        </Field>
    );
};
