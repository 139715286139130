import { FC, PropsWithChildren, forwardRef, memo } from 'react';
import { classnames } from '../../../lib/classnames';
import { Loader } from '../../Loader/ui/Loader';
import { ButtonSize, ButtonTheme } from '../model/types';
import cls from './Button.module.css';

interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        React.RefAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    theme?: ButtonTheme;
    size?: ButtonSize;
    icon?: boolean;
    square?: boolean;
}

const ButtonComp: FC<PropsWithChildren<ButtonProps>> = forwardRef(
    (
        {
            children,
            type = 'button',
            disabled,
            isLoading = false,
            className = '',
            theme = 'primary',
            size = 'md',
            icon = false,
            square = false,
            ...otherProps
        },
        ref,
    ) => {
        return (
            <button
                type={type}
                ref={ref}
                disabled={disabled || isLoading}
                className={classnames(
                    cls.button,
                    className,
                    cls[theme],
                    cls[size],
                    {
                        [cls.icon]: icon,
                        [cls.loading]: isLoading,
                        [cls.square]: square,
                    },
                )}
                {...otherProps}>
                {isLoading && <Loader className={classnames('!h-5 !w-5')} />}
                {children}
            </button>
        );
    },
);

export const Button = memo(ButtonComp);
