import React, { PropsWithChildren } from 'react';
import {
    FieldError,
    FieldValues,
    Path,
    UseFormRegister,
} from 'react-hook-form';
import { classnames } from '../../../lib/classnames';
import { FieldSize } from '../model/types';
import cls from './Field.module.css';
import { Skeleton } from './Skeleton';

export type FieldProps<T extends FieldValues> = {
    formKey: Path<T>;
    label: string;
    hint?: string;
    error?: FieldError;
    required?: boolean;
    size?: FieldSize;
    fieldClassName?: string;
    isLoading?: boolean;
};

export type FieldChildrenProps<T extends FieldValues> = FieldProps<T> & {
    register: UseFormRegister<T>;
};

export const Field = <T extends FieldValues>(
    props: PropsWithChildren<FieldProps<T>>,
) => {
    const {
        fieldClassName,
        size = 'm',
        children,
        formKey: key,
        label,
        error,
        hint,
        required,
        isLoading,
    } = props;

    if (isLoading) {
        return (
            <Skeleton
                label={label}
                hint={hint}
                size={size}
                fieldClassName={fieldClassName}>
                {children}
            </Skeleton>
        );
    }
    return (
        <div
            className={classnames(
                {
                    [cls['wrapper-xs']]: size === 'xs',
                    [cls['wrapper-s']]: size === 's',
                    [cls['wrapper']]: size === 'm',
                    [cls['wrapper-l']]: size === 'l',
                    [cls['wrapper-xl']]: size === 'xl',
                },
                fieldClassName || '',
            )}>
            {label && (
                <label htmlFor={key} className={cls.label}>
                    {label}
                </label>
            )}
            <div
                className={classnames(cls.innerControl, {
                    [cls.innerControlError]: !!error,
                })}>
                {children}
                {required && (
                    <span>
                        <span className="text-error">* </span>
                        <p className={cls.messageInfo}>
                            Поле обязательно для заполнения
                        </p>
                    </span>
                )}
                {hint && <p className={cls.messageInfo}>{hint}</p>}
                {error && (
                    <p className={cls.messageError}>
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </div>
        </div>
    );
};
