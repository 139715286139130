export function saveInStorage(
    key: string | string[],
    value: string | string[],
    isLocalStorage = false,
) {
    if (Array.isArray(key) && Array.isArray(value)) {
        key.forEach((k, i) =>
            isLocalStorage
                ? localStorage.setItem(k, value[i])
                : sessionStorage.setItem(k, value[i]),
        );
    } else if (typeof key === 'string' && typeof value === 'string') {
        isLocalStorage
            ? localStorage.setItem(key, value)
            : sessionStorage.setItem(key, value);
    }
}
