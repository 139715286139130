import { FC, useState, useEffect } from 'react';
import caret from 'shared/assets/icons/caret.svg';
import mapPin from 'shared/assets/icons/map-pin.svg';
import { classnames } from 'shared/lib/classnames';
import cls from './HeaderCity.module.css';

interface HeaderCityProps {}

export const HeaderCity: FC<HeaderCityProps> = () => {
    const [openList, setOpenList] = useState(false);
    const onToggleDropdown = () => {
        setOpenList((prev) => !prev);
    };

    useEffect(() => {
        const handleClick = (event: any) => {
            const target = event.target as HTMLElement;
            if (!target.closest(`.${cls.headerCity}`)) {
                if (!target.closest(`.${cls.headerCity} ul:not(.hidden)`)) {
                    setOpenList(false);
                }
            }
        };

        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className={cls.headerCity}>
            <button
                className={classnames(
                    'flex items-center rounded-full border border-primary-50 p-1 pl-3',
                    { 'border-primary-base': openList },
                )}
                onClick={onToggleDropdown}>
                <img className="mr-1 size-6" src={mapPin} alt="" />
                <span className="">Москва</span>
                <span className="ml-16 flex size-10 shrink-0 items-center justify-center rounded-full bg-primary-20 transition-colors hover:bg-primary-50">
                    <img src={caret} alt="" />
                </span>
            </button>
            <ul
                className={classnames(
                    'absolute top-full w-full translate-y-2 list-none rounded-lg border border-primary-base bg-white p-1',
                    {
                        hidden: !openList,
                    },
                )}></ul>
        </div>
    );
};
