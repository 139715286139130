import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AppRouterProvider } from './providers/router';

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <AppRouterProvider />
        </DndProvider>
    );
}

export default App;
