import React, { FormEventHandler, PropsWithChildren } from 'react';
import { Loader } from '../../Loader';

export type FormProps = {
    inProgress?: boolean;
    onSubmit: FormEventHandler<HTMLFormElement>;
    className?: string;
};

export const Form: React.FC<PropsWithChildren<FormProps>> = ({
    inProgress,
    onSubmit,
    children,
    className = '',
}) => {
    return (
        <form onSubmit={onSubmit} className={`relative ${className}`}>
            {inProgress && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                    <div className="absolute inset-0 bg-white opacity-40"></div>
                    <Loader className="z-20 text-gray-900" />
                </div>
            )}
            {children}
        </form>
    );
};
