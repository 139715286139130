import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit';
import { authApi, authReducer } from 'features/auth';
import { generatedApi } from '../../../api/emptyApi';

export const store = configureStore({
    reducer: {
        [generatedApi.reducerPath]: generatedApi.reducer,
        auth: authReducer,
        [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            authApi.middleware,
            generatedApi.middleware,
        ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
