import { FC } from 'react';
// import cls from './Footer.module.css';

interface FooterProps {}

export const Footer: FC<FooterProps> = () => {
    return (
        <footer className="flex justify-between border-t border-stroke-50 p-6 px-4 text-sm sm:px-6 sm:py-3 sm:text-base xl:px-20 xl:py-5">
            <div className="flex flex-col  sm:flex-row sm:gap-20 ">
                <span className=" text-stroke-500 ">
                    ООО "Технологии оценки"
                </span>
                <span className="text-stroke-500">ОГРН 1221600106705</span>
            </div>
            <div>
                <span className="text-stroke-500">Оценка.рф</span>
            </div>
        </footer>
    );
};
