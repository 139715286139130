import { FC } from 'react';
import { HeaderCity } from 'features/headerCity';
import { ReactComponent as UserIcon } from 'shared/assets/icons/user.svg';
import logo from 'shared/assets/images/logo.svg';
import { Button } from 'shared/ui/Button/ui/Button';
import { LinkApp } from 'shared/ui/LinkApp';

interface NavbarProps {}

export const Navbar: FC<NavbarProps> = () => {
    return (
        <header className="rounded-b-2xl border border-t-0 border-stroke-50 bg-white p-4 md:px-10 md:py-4 xl:px-20 xl:py-6">
            <div className="flex items-center justify-between gap-9 lg:justify-normal lg:gap-20">
                <a href="/" className="flex shrink-0 items-center gap-2">
                    <img
                        className="mx-auto h-6 w-auto"
                        src={logo}
                        alt="logo"
                        width="138"
                        height="24"
                    />
                </a>
                <div className="flex justify-between gap-4 lg:w-full">
                    <div className="flex items-center lg:gap-10">
                        <LinkApp
                            theme="secondary"
                            to="https://оценка.рф/partners"
                            className="mr-4 lg:mr-10">
                            Партнерам
                        </LinkApp>
                        <HeaderCity />
                        <Button
                            theme="white"
                            icon
                            square
                            className="border border-primary-100 lg:!hidden">
                            <UserIcon className="size-6 text-primary-base" />
                        </Button>
                    </div>
                    <div className="hidden items-center divide-x-2 divide-gray-100 lg:flex">
                        <LinkApp theme="secondary" to="/" className="mr-10">
                            Личный кабинет
                        </LinkApp>
                        <LinkApp
                            to={'https://mrqz.me/65e56148c10d08002629d0ea'}
                            target="_blank"
                            className="pl-5">
                            <Button className="">Заказать оценку</Button>
                        </LinkApp>
                    </div>
                </div>
            </div>
        </header>
    );
};
