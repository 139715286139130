import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/api/baseQuery';
import { TOKEN_KEY } from 'shared/const/localStorage';
import { saveInStorage } from 'shared/lib/saveInStorage';
import { authActions } from '../model/slice/authSlice';
import {
    SmsCheckArg,
    SmsCheckResponse,
    SmsSendArg,
    SmsSendResponse,
} from './types';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        smsSend: builder.mutation<SmsSendResponse, SmsSendArg>({
            query: (body) => ({
                url: '/api/v1/auth/sms/send/',
                method: 'POST',
                body: { phoneNumber: body.phoneNumber },
            }),
        }),
        smsCheck: builder.mutation<SmsCheckResponse, SmsCheckArg>({
            query: (body) => ({
                url: '/api/v1/auth/sms/check/',
                method: 'POST',
                body: { phoneNumber: body.phoneNumber, code: body.code },
            }),
            extraOptions: {
                skipAuth: true,
            },
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(authActions.setToken(data.token));
                    saveInStorage(TOKEN_KEY, data.token, true);
                } catch (error) {}
            },
        }),
    }),
});

export const { useSmsSendMutation, useSmsCheckMutation } = authApi;
