import { FC } from 'react';
import { Loader } from 'shared/ui/Loader';

interface PageLoaderProps {}

export const PageLoader: FC<PageLoaderProps> = () => {
    return (
        <div className="flex h-full items-center justify-center">
            <Loader className="!h-16 !w-16 !border-4 border-primary-base" />
        </div>
    );
};
